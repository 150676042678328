.become-partner-container {
    background-color: $graySection;
    display: flex;
    padding: 2rem 0;

    .container {
        display: flex;
        flex-direction: row;
    }

    p {
        font-size: 1rem;
        margin: 1rem 0 2rem;
    }

    .become-partner-content,
    .become-partner-image {
        flex: 1;
        padding: 1rem;

        img {
            border-radius: 0.2rem;
            min-height: 100%;
            object-fit: cover;
            margin-top: -1rem;
        }
    }

    .become-partner-content {
        padding: 1rem;
    }

    .become-partner-image {
        max-width: 30rem;
        margin-right: 1rem;
    }
}

@media (max-width: 600px) {
    .become-partner-container {
        padding: 2rem 1rem 0 1rem;

        .container {
            flex-direction: column;
        }

        .become-partner-image {
            margin-top: 1rem;
            margin-bottom: 0;
            padding: 1rem 0 0 1rem;
        }
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .become-partner-container {
        padding: 2rem;

        .become-partner-image {
            margin-top: 1rem;
            margin-bottom: 0;
            padding: 1rem 0 0 0;
        }
    }
}

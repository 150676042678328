.empty-legs-wrapper {
    margin-bottom: 2rem;
}

.empty-leg-container {
    background: white;
    width: 32rem;
    border-radius: 1.5rem;
    box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
    margin-bottom: 3rem;
    align-self: flex-start;

    .plane-container {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        padding-bottom: 0;
        overflow: hidden;
        background-size: cover;

        .plane-info {
            border-top-left-radius: 1.5rem;
            border-top-right-radius: 1.5rem;
            margin: 10rem 0 0 0;
            background: white;
            display: flex;
            flex-direction: row;
            padding: 1rem 2.5rem 0 2.5rem;
            justify-content: space-between;

            h2 {
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 0;
            }

            .plane-category {
                background: black;
                padding: 0.3rem 0.5rem !important;
                border-radius: 0.3rem;
                color: white;
                min-height: auto;
                align-self: center;
                font-size: 0.8rem;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .separator-container {
            background: white;
            margin-bottom: 0;
            padding: 0 2.5rem;

            .separator {
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }

    .padding-container {
        padding: 2rem 2.5rem 1.5rem;
    }

    .small-top-padding {
        padding-top: 1rem;
    }

    span {
        font-weight: 600;
        color: #828d92;
        font-size: 1rem;
    }

    .route {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
            font-weight: 600;
            font-size: 2rem;
            line-height: 120%;
            margin-bottom: 0;
            font-family: 'Uber Move Text', sans-serif;
            background: white url('../../../assets/images/icons/departure.svg') no-repeat right 1rem;
            background-size: 2.2rem;
            padding-right: 3.2rem;
            text-transform: uppercase;
        }

        span {
            position: relative;
            top: 0.3rem;
        }

        div:last-child {
            justify-content: flex-end;
            align-items: flex-end;

            p,
            span {
                text-align: right;
            }

            p {
                background: white url('../../../assets/images/icons/landing.svg') no-repeat left 0.7rem;
                padding-left: 3.2rem;
                background-size: 2.2rem;
                padding-right: 0;
            }

            span {
                text-align: right;
                align-self: flex-end;
                display: block;
            }
        }
    }

    .route-separator {
        flex: 1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239BA9B0FF' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='round'/%3e%3c/svg%3e");
        background-size: 100% 10px;
        margin: 1.4rem 2rem 0;
        height: 2px;
        display: inline-block;
    }

    .separator {
        flex: 1;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239BA9B0FF' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='2' stroke-linecap='round'/%3e%3c/svg%3e");
        background-size: 100% 10px;
        margin: 1.5rem 0 1rem;
        height: 1px;
        display: inline-block;
        width: 100%;
    }

    .leg-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            font-size: 1.2rem;
        }

        .date {
            padding-left: 2.5rem;
            padding-top: 0.1rem;
            background: url('../../../assets/images/icons/calendar.svg') no-repeat left 0.3rem;
            background-size: auto 1.8rem;
        }

        .users {
            padding-right: 2.5rem;
            background: url('../../../assets/images/icons/users.svg') no-repeat right 0.2rem;
            padding-top: 0.2rem;
            position: relative;
            top: 0.3rem;
            background-size: auto 1.8rem;
        }
    }

    .super {
        vertical-align: super;
        font-size: 0.8rem;
    }

    .buttons {
        flex: 1;
        display: flex;

        button {
            text-align: center;
            flex: 1;
            padding: 1rem;
            text-transform: uppercase;
            background: black;
            font-weight: bold;
            color: white;
            font-size: 1.1rem;
            font-family: 'Uber Move Text', sans-serif;
            border: none;
            cursor: pointer;

            &:active {
                color: darken(white, 50%);
            }

            &:first-child {
                border-bottom-left-radius: 1.5rem;

                &:hover {
                    background: lighten(black, 20%);
                }

                &:active {
                    background: black;
                }
            }

            &:last-child {
                border-bottom-right-radius: 1.5rem;
                background: #404749;

                &:hover {
                    background: lighten(#404749, 20%);
                }

                &:active {
                    background: darken(#404749, 5%);
                    color: black;
                }
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .empty-legs-page-container {
        h1 {
            font-size: 1.4rem !important;
        }
    }

    .empty-leg-container {
        max-width: 100%;
        margin-bottom: 2rem;

        .padding-container {
            padding: 1.5rem;
        }

        .route {
            .route-separator {
                padding: 0;
                margin-top: 0.7rem;
                margin-bottom: 0;
                flex: 1;
            }

            p {
                font-size: 1.4rem;
                padding: 0 !important;
                background: none !important;
            }

            span {
                display: block;
                font-size: 0.9rem;
                line-height: 120%;
                margin-top: 0.5rem;
            }
        }

        .leg-footer {
            .date {
                background-size: auto 1.4rem;
                background-position: left center;
                padding-left: 2rem;
                font-size: 1rem;
            }

            .super {
                font-size: 0.8rem;
            }

            .users {
                padding-right: 2rem;
                top: 0.1rem;
                font-size: 1rem;
                background-size: auto 1.5rem;
            }
        }

        .buttons {
            a {
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (min-width: 400px) and (max-width: 520px) {
    .empty-legs-page-container {
        h1 {
            font-size: 2rem !important;
        }
    }
}

@media screen and (min-width: 521px) and (max-width: 900px) {
    .empty-legs-wrapper {
        background-color: $graySection;
        display: flex;
        flex-flow: row wrap;
        flex-wrap: wrap;
        flex: 0.47 0.47;
        justify-content: space-between;
        padding: 2rem 2rem 1rem;

        .empty-leg-container {
            max-width: 100%;

            .padding-container {
                padding: 1.5rem;
            }

            .route {
                .route-separator {
                    padding: 0;
                    margin-top: 0.7rem;
                    margin-bottom: 0;
                    flex: 1;
                }

                p {
                    font-size: 1.4rem;
                    padding: 0 !important;
                    background: none !important;
                }

                span {
                    display: block;
                    font-size: 0.9rem;
                    line-height: 120%;
                    margin-top: 0.5rem;
                }
            }

            .leg-footer {
                .date {
                    background-size: auto 1.4rem;
                    background-position: left center;
                    padding-left: 2rem;
                    font-size: 1rem;
                }

                .super {
                    font-size: 0.8rem;
                }

                .users {
                    padding-right: 2rem;
                    top: 0.1rem;
                    font-size: 1rem;
                    background-size: auto 1.5rem;
                }
            }

            .buttons {
                a {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .empty-leg-container {
        width: 48%;
    }
}

@media screen and (min-width: 901px) and (max-width: 1220px) {
    .empty-legs-wrapper {
        background-color: $graySection;
        display: flex;
        flex-flow: row wrap;
        flex-wrap: wrap;
        flex: 0.47 0.47;
        justify-content: space-between;
        padding: 2rem 2rem 1rem;
    }

    .empty-leg-container {
        width: 48%;
    }
}

@media screen and (min-width: 1221px) {
    .empty-legs-page-container {
        background-color: $graySection;
        margin-bottom: 2rem;
        padding-top: 2rem;

        .empty-legs-wrapper {
            display: flex;
            flex-flow: row wrap;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 2rem 2rem 1rem;
            max-width: 1152px;
            margin: auto;
            flex: 1;
        }
    }
}

@media screen and (min-width: 1440px) {
    .empty-legs-page-container {
        .empty-legs-wrapper {
            max-width: 1400px;
            justify-content: flex-start;
        }

        .empty-leg-container {
            width: 30%;
            margin-right: 2%;

            .route {
                box-sizing: border-box;

                .route-separator {
                    margin: 1.4rem 0.5rem 0 0.5rem;
                }

                div:last-child {
                    p {
                        margin-left: -2rem;
                    }
                }
            }
        }
    }
}

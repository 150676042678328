.buttons-picker-container {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    .button-item {
        border: none;
        align-items: center;
        background: $input-field-color;
        flex: 1;
        cursor: pointer;
        line-height: 1rem;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        min-width: 30%;
        font-weight: 500;
        font-size: 1rem;
        padding: 1rem 0.5rem;
        border-radius: 0.4rem;
        text-align: center;
        text-transform: capitalize;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }

        &:hover,
        &.selected {
            background: $primaryAction;
            color: $white;

            label {
                color: $white;
            }
        }

        label {
            cursor: pointer;
            width: 100%;
        }

        input {
            display: none;
            flex: inherit;
            height: auto;
            margin-left: 1rem;
            min-width: auto;
            width: auto;
        }
    }
}

.form-section-title {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 1rem 0 0.5rem 0;
}

.select-container {
    position: relative;

    .from-to-line {
        position: absolute;
        display: flex;
        top: 15%;
        left: 1rem;
        height: 60%;
        align-content: center;
        justify-content: center;
        object-fit: contain;

        img {
            height: 100%;
            width: 0.45rem;
            align-self: center;
        }
    }
}

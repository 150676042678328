.privacy-policy,
.terms-and-conditions {
    padding: 2rem;
    font-size: 1rem;
    max-width: 70%;
    margin-left: 15%;

    h2 {
        font-size: 1.6rem;
        font-weight: 700 !important;
    }

    li,
    p {
        padding-bottom: 1rem;
    }
}

@media (max-width: 600px) {
    .privacy-policy,
    .terms-and-conditions {
        padding: 1rem;
        max-width: 100%;
        margin-left: 1rem;
    }
}

.membership-container {
    min-height: 60vh;

    .error {
        color: red;
        font-weight: bold;
    }

    .choose-container {
        justify-content: space-between;

        .text-container {
            max-width: 55%;
        }

        .form-container {
            max-width: 30%;
        }
    }

    form {
        input {
            width: 75%;
        }
    }

    .cancel {
        margin-top: 1rem;
        border: none;
        border-radius: 0.3rem;
        padding: 0.5rem 1rem;
        font-weight: 600;

        &:hover {
            cursor: pointer;
        }
    }
}

@media (min-width: 651px) and (max-width: 800px) {
    .membership-container {
        margin: 2rem;
    }
}

@media (max-width: 650px) {
    .membership-container {
        margin: 2rem 1rem 2rem 2rem;

        .choose-container {
            flex-direction: column;

            .text-container,
            .form-container {
                max-width: 100%;

                p {
                    padding-right: 0;
                }
            }
        }
    }
}

.form-footer {
    margin: 2rem 0 0 0;

    button {
        padding: 0.7rem 1rem 0.5rem 1rem;
        margin-right: 1rem;
    }
}

@media (max-width: 600px) {
    .form-footer {
        margin-left: 0;
    }
}

.safety-section {
    padding: 3rem 0;

    .container {
        display: flex;
        flex-direction: row;

        .safety-content,
        .safety-image {
            flex: 1;
        }

        .safety-image {
            max-width: 30%;
            margin-left: 2rem;
            min-height: 100%;
            background: url('../../../assets/images/safety.webp');
            background-size: cover;
            border-radius: 0.2rem;
        }
    }

    .bullets-container {
        margin: 1.5rem 0 3rem;

        p {
            background: url('../../../assets/images/checked.png') no-repeat left 0.4rem;
            background-size: 0.7rem auto;
            margin: 0;
            padding: 0 0 0 1.2rem;
            font-size: 1rem;
        }
    }
}

@media (max-width: 600px) {
    .safety-section {
        padding: 2rem;
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .safety-section {
        padding: 2rem;
    }
}

.our-standards {
    .column {
        padding-right: 2rem;
        font-size: 1rem;
        font-weight: 400;

        img {
            min-width: 5rem;
            margin: 1rem 2rem 1rem 1rem;
            object-fit: contain;
            max-height: 4rem;
        }
    }

    p {
        font-size: 1rem !important;
        font-weight: 400;
    }

    .rows {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 1000px) {
    .our-standards {
        padding: 1rem 2rem;
    }
}

$small-mobile: 320px;
$small-mobile-horizontal: 568px;

$mobile: 375px;
$mobile-horizontal: 667px;

$large-mobile: 600px;
$large-mobile-horizontal: 736px;

$tablet: 800px;
$tablet-width-horizontal: 800px;

$desktop: 1024px;

$desktopHd: 1440px;

$desktopFhd: 1920px;

@mixin small-mobile {
    @media (max-width: #{$small-mobile}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin large-mobile {
    @media (max-width: #{$large-mobile}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$desktop}) {
        @content;
    }
}

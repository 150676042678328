.pagination {
    display: flex;
    justify-content: center;
    padding: 1rem 0 2rem;
    font-family: 'Uber Move Text', sans-serif;

    li {
        a {
            @extend .primary-btn;
            @extend .small-padding;

            padding: 0.7rem 1rem;
            margin: 0 0.3rem;
            border-radius: 0.3rem;
            border: solid 1px white;
            background: white;
            color: black;
        }

        &.disabled {
            a {
                color: #afafaf;
                background: #eee;
                border-color: #eee;
                cursor: default;
            }
        }
    }
}

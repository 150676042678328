.modal-alert-container {
    background-color: rgba(0, 0, 0, 0.82);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;

    .modal-alert-content {
        width: 30vw;
        margin: auto;
        min-height: 20vh;
        background: $modal-background url('../../../assets/images/justfly-plane-wired.svg') no-repeat center 1rem;
        background-size: auto 1.5rem;
        border-radius: 1rem;
        padding: 1.5rem 1rem 0.5rem 1rem;
        text-align: center;
        max-height: 90vh;
        overflow: auto;

        .modal-message {
            font-size: 1rem;
        }

        .action-footer {
            padding-top: 0.5rem;
            margin-bottom: 1rem;
            flex: 1;

            button:first-child {
                margin-right: 1rem;
            }
        }
    }
}

@media (max-width: 600px) {
    .modal-alert-content {
        min-width: 86vw;
    }
}

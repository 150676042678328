.charter-inquiry-container {
    padding: 2rem;

    .column {
        img {
            border-radius: 0.2rem;
            height: 101%;
            object-fit: cover;
        }

        &:first-child {
            padding-left: 0;
            margin-left: -1.5rem;
        }
    }

    .chosen-route {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
            flex: 1;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        strong {
            display: block;
            font-size: 1.2rem;
        }
    }

    .form-section-title {
        font-size: 1rem;
        font-weight: 700;
    }

    input,
    textarea {
        width: 20rem;
    }

    .small-field {
        width: 12rem;
    }

    .dates-group {
        display: flex;
        flex-direction: row;

        .date-input-container {
            .input-field {
                max-width: 95%;
            }
        }

        .date-input-container:nth-child(2) {
            .input-field {
                position: relative;
                left: 5%;
            }
        }
    }

    textarea {
        @extend .input-field;

        display: block;
        margin-top: 0.2rem;
    }

    .flight-type-container {
        .error-info {
            margin-top: 1rem;
            width: auto;
            display: inline-flex;
            padding: 0.5rem 1rem !important;
            margin-bottom: auto;
        }
    }
}

.error-field {
    border: solid 2px $red-color;
    display: block;
}

.error-field-message {
    background: $red-color;
    font-size: 0.9rem !important;
    padding: 0.2rem  1rem;
    margin: 0.3rem 0;
    display: inline-table;
    border-radius: 0.4rem;
    width: auto;
    clear: both;
    color: white;
}

.trip-picker-container {
    display: flex;
    flex-direction: row;

    button {
        cursor: pointer;
        background: #edeeed;
        border: solid 3px #0a0a0a;
        color: #0a0a0a;
        flex: 1;
        font-size: 1rem;
        padding: 0.4rem 0.8rem 0.5rem 0.8rem;
    }

    .selected {
        cursor: pointer;
        background: black;
        background-color: #0a0a0a;
        color: #fff;
    }
}

.full-column {
    font-size: 1rem;
    font-weight: 400;

    label {
        font-size: 1rem;
        font-weight: 400;
        display: block;
    }
}

.book-a-charter-img {
    background: url('../../../assets/images/book-a-charter.webp') no-repeat center center;
    background-size: cover;
    border-radius: 0.2rem;
    margin-right: 1rem;
}

@media (max-width: 600px) {
    .charter-inquiry-container {
        .column:first-child {
            display: none;
        }

        input,
        textarea {
            width: 100%;
        }
    }
}

@media (max-width: 800px) {
    .book-a-charter-img {
        display: none;
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .book-a-charter-img {
        margin-left: 0.5rem !important;
    }
}

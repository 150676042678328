.hero-section {
    max-height: 14rem;
    padding-top: 3rem;
    height: inherit;
    padding-left: 2rem;

    .heading {
        color: #081f5d !important;
        font-size: 2rem;
    }

    p {
        margin: 0;
        line-height: 1.5rem;
        text-align: left;
        color: #081f5d !important;
        font-size: 1.2rem;
        margin-top: 0.5rem !important;
        font-weight: 400;
    }
}

@media (max-width: 375px) {
    .hero-section {
        max-height: 15rem;

        .heading {
            font-size: 1.5rem !important;
        }
    }
}

@media (min-width: 375px) and (max-width: 400px) {
    .hero-section {
        max-height: 14.4rem;

        .heading {
            max-width: 90%;
            font-size: 1.7rem !important;
            text-shadow: 0 0 8px #fdf6f2ec;
            margin-bottom: 0;
        }
    }
}

@media (min-width: 401px) and (max-width: 500px) {
    .hero-section {
        max-height: 14rem;
    }
}

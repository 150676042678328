.heading {
    font-weight: bold;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    text-transform: none;
}

.about-us-container {
    min-height: 65vh;

    @include desktop {
        padding: 0 2rem;
    }

    @include tablet {
        padding: 1rem;
    }
}

.choose-container {
    display: flex;
    margin-bottom: 2rem;

    @include large-mobile {
        display: block;
    }

    .text-container {
        flex: 1;

        .text {
            margin-bottom: 1rem;
            max-width: 100% !important;
            width: 100%;
            font-size: 1rem;
            line-height: 1.4rem;
            text-align: left;
            color: $primaryAction;
            padding-right: 2rem;

            strong {
                color: $primaryAction;
            }
        }

        .img-container {
            background: url(../../assets/images/about-us-low.jpg) no-repeat center;
            background-size: cover;
            width: 100%;
            height: 15rem;
            flex: 1;
            display: none;
            margin: 1rem 0;

            @include tablet {
                display: block;
            }
        }
    }

    .img-container {
        background: url(../../assets/images/about-us.jpg) no-repeat center;
        background-size: cover;
        flex: 1;
        border-radius: 0.2rem;

        @include tablet {
            display: none;
        }
    }
}

.cards_container {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @include tablet {
        grid-template-columns: 1fr 1fr;
    }

    @include small-mobile {
        grid-template-columns: 1fr;
    }
}

.about-reasons {
    color: #b0acac;
    font-size: 1rem;
    list-style: none;
    margin: 0.5rem 0 1.2rem;
    padding: 0 0 0 1rem;
    width: 100%;

    li {
        background: url(../../assets/images/checked-gray.png) no-repeat left 0.5rem;
        background-size: 0.7rem;
        font-weight: bold;
        padding-bottom: 0.2rem;
        padding-left: 1.2rem;
    }
}

.moto-text {
    margin: 0 2rem;

    @include large-mobile {
        margin: unset;
    }
}

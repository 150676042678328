.daily-offers-container {
    p {
        font-size: 1rem;
    }

    .subscribe-form {
        span {
            margin-left: 1rem;
            display: inline-block;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .daily-form-container {
        min-width: 100%;
        margin-bottom: 4rem;

        .form-container {
            max-width: 30rem;
        }

        .centered {
            align-items: center;
            display: flex;
            padding-left: 3rem;

            img {
                max-width: 15rem;
            }
        }
    }

    .daily-offers-info {
        max-width: 70%;
        margin-bottom: 30px;
    }
}

.subscribe-teaser {
    margin-left: 0;
    padding-bottom: 2rem;

    p {
        margin: 0;
        padding-bottom: 0.5rem;
        padding-left: 0;

        span {
            font-size: 1.2rem;
            font-weight: bold !important;
            color: black;
            display: block;
            margin-left: 0;
        }
    }

    .primary-btn {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        margin: 0 0 0 1rem;
    }
}

.how-it-works {
}

.confirmation-container {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
}

/* MEDIA */
@media (max-width: 600px) {
    .daily-offers-container {
        padding: 0 2rem;

        .moto-container {
            min-width: 110%;
            position: relative;
            left: -1rem;
        }

        .subscribe-form {
            span {
                margin-left: 0.5rem;
                margin-top: 1rem;
            }
        }

        .daily-form-container {
            .centered {
                padding-left: 2rem;
                margin-top: 3rem;
            }
        }

        .daily-offers-info {
            max-width: 100%;
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .daily-offers-container {
        .container {
            padding: 0 2rem;
        }
    }
}

@media (min-width: 901px) and (max-width: 1025px) {
    .daily-offers-container {
        .container {
            padding: 0 2rem;
        }
    }
}

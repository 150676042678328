@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

$primaryAction: #000;
$secondary: #fff;
$primaryActionTitle: #fbf9f9;
$cancelAction: #e7e7e7;
$sectionBorder: #f2f2f2;

$white: #fff;
$textColor: black;
$lightGray: rgba(0, 0, 0, 0.5);
$graySection: #e8e8e8;

/* Fonts */
$blueHeading: #081f5d;
$motoText: #757575;

$night-rider: #090404;
$link-hover-color: rgba(255, 255, 255, 0.1);
$icon-hover-color: #333;

$silver-chalice: #afafaf;
$silver: #ccc;

$cod-gray: #0a0a0a;

$input-field-color: #edeeed;

$red-color: #ed747d;
$green-color: #77f5c0;

$modal-background: #c9c9c9;

@font-face {
    font-family: 'Uber Move Text Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/UberMoveTextRegular.otf');
    font-display: swap;
}

@font-face {
    font-family: 'Uber Move Text Light';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('../../assets/fonts/UberMoveTextLight.otf');
}

@font-face {
    font-family: 'Uber Move Text Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/UberMoveTextMedium.otf');
    font-display: swap;
}

@font-face {
    font-family: 'Uber Move Text Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/UberMoveTextBold.otf');
    font-display: swap;
}

.book-charter-container {
    padding: 3rem 0;

    .book-charter {
        display: flex;
        flex-direction: row;

        .book-charter-form {
            flex: 1;
            max-width: 35%;
            margin-left: 2rem;

            p {
                font-weight: 400;
                margin: 0;
                padding: 0 0 1rem 0;
                line-height: 130%;
                font-size: 1.1rem;
            }
        }

        .book-charter-image {
            flex: 1;
            border-radius: 0.2rem;
            background: url('../../../assets/images/book-a-charter.webp') no-repeat top center;
            background-size: cover;
        }
    }
}

@media (max-width: 600px) {
    .book-charter-container {
        padding: 2rem 0;
    }

    .book-charter {
        flex-direction: column;

        .book-charter-form {
            min-width: 85%;
        }
    }

    .book-charter-image {
        display: none;
    }
}

@media (min-width: 601px) and (max-width: 800px) {
    .book-charter-container {
        padding: 2rem;

        .book-charter-image {
            max-width: 10rem;
        }
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .book-charter-container {
        padding: 2rem;

        .book-charter {
            .book-charter-form {
                min-width: 45%;
            }
        }
    }
}

.card_container {
    @include tablet {
        margin: 0.5rem;
    }

    &:nth-child(odd) {
        background: #d0cece;

        .descrption {
            color: $primaryAction;
        }
    }

    &:nth-child(even) {
        background: $secondary;
        border: 2px solid #d0cece;
    }

    .icon_card {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 3rem;
            height: 3rem;
        }
        img {
            width: 3rem;
            height: 3rem;
        }
    }

    .title_card {
        padding-top: 0.5rem;
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 600;
        min-width: 100%;
        text-align: center;
        color: $primaryAction;
        line-height: 1.4rem;
        margin: 0;

        @include large-mobile {
            font-size: 1rem;
        }

        .strong_text {
            color: black;
            display: block;
            font-size: 1.7rem;
            padding-bottom: 0.5rem;
            font-weight: 900;

            @include large-mobile {
                font-size: 1.5rem;
            }
        }
    }

    .descrption {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 0.5rem;
        text-align: center;

        @include large-mobile {
            font-size: 0.8rem;
            margin: 0;
        }
    }
}

.padding_row {
    padding: 0.3rem 0.5rem;
}

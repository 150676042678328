p,
strong,
span {
    color: $textColor;
    font-weight: 400;
}

strong {
    font-weight: 900;
}

a,
input {
    outline: none;
}

.column {
    p {
        font-size: 1rem;
        font-weight: 400;
    }

    ul.list {
        text-align: left;
        font-size: 1rem;
        list-style: none;
        margin: 0 0 1rem;
        padding: 0;
        width: 100%;

        li {
            padding: 0;
            margin: 0;
            background: url('../../images/checked.png') no-repeat left 0.4rem;
            background-size: 0.7rem;
            font-weight: 400;
            padding-bottom: 0;
            padding-left: 1.2rem;

            p {
                margin: 0;
                padding: 0;
            }
        }
    }
}

.error-info,
.success-info {
    color: $white;
    text-align: left;
    padding: 1rem !important;
    background-color: $red-color;
    border-radius: 0.4rem;
    font-weight: 500;
    font-size: 1rem !important;
}

.success-info {
    background-color: $green-color;
    color: $primaryAction;
}

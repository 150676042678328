.header_container {
    background: $primaryAction;
    padding: 0 2rem;
    position: relative;

    @include large-mobile {
        padding-left: 2rem;
        padding-right: 1.4rem;

        &.fixed_header {
            top: 0;
            position: fixed;
            width: 100%;
            z-index: 1;
        }
    }

    @include small-mobile {
        padding-left: 0;
        padding-right: 0;
    }

    .content_navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 94vw;

        a {
            color: $secondary;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: 400;

            @media (max-width: 1330px) {
                margin-right: 0;
            }

            &:hover {
                button {
                    background-color: $icon-hover-color;
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            list-style: none;
            margin-bottom: 0;

            @include small-mobile {
                margin-right: 0.5rem;
            }

            li:first-child {
                a img.logo {
                    min-height: 2.5rem;
                    width: auto;
                }
            }

            .link {
                &:hover {
                    background-color: $icon-hover-color;
                    color: $silver;
                }

                .name_link {
                    color: $secondary;
                    font-weight: 400;
                    padding: 1.2rem 1rem;

                    @include tablet {
                        font-size: 0.9rem;
                        padding: 1.2rem 0.5rem;
                    }

                    @media (max-width: 670px) {
                        font-size: 0.6rem;
                    }

                    @include large-mobile {
                        display: none;
                    }
                }
            }

            &.right-menu {
                @include large-mobile {
                    display: none;
                }

                a {
                    .button {
                        border: none;
                        margin-left: 0;

                        img {
                            max-height: 1.4rem;
                        }
                    }
                }
            }
        }

        .logo_container {
            display: flex;
            align-items: center;

            .logo {
                max-width: 14rem;
                height: 1.5rem;
                margin-right: 1.5rem;

                @include mobile {
                    height: 1.1rem;
                }

                @include small-mobile {
                    margin-left: 0.5rem;
                }
            }
        }

        .button {
            background: transparent;
            border: none;
            width: 3rem;
            height: 3rem;
            cursor: pointer;
            border-radius: 50%;

            @include mobile {
                padding: 0;
                width: 2rem;
                height: 2rem;
            }

            &:hover {
                background-color: $icon-hover-color;

                @include mobile {
                    background: transparent;
                }
            }

            .img {
                display: inline;
                max-width: 2rem;
                height: auto;
                vertical-align: middle;

                @include tablet {
                    max-width: 1.3rem;
                }

                @include mobile {
                    width: 1.3rem;
                    height: auto;
                }
            }
        }

        span {
            color: $secondary;

            @include mobile {
                font-size: 0.8rem;
                margin-left: -0.4rem;
            }
        }

        .text {
            @include desktop {
                display: none;
            }
        }

        .mobile-nav {
            display: none;

            @include large-mobile {
                display: block;

                button {
                    padding: 1rem 0;
                    background: transparent;
                    border: none;
                    font-size: 1.5rem;
                    color: $white;
                    font-weight: bold;
                    min-width: 2rem;
                    cursor: pointer;
                    height: 4rem;

                    svg {
                        width: 1.7rem;
                    }
                }

                .button-close {
                    border: none;
                    font-size: 1.5rem;
                    color: transparent;
                    font-weight: bold;
                    min-width: 2rem;
                    cursor: pointer;
                    height: 4rem;
                    background: url(../../../assets/images/closeBtn.svg) no-repeat center center;
                    object-fit: contain;
                    background-size: 65%;
                }
            }
        }
    }
}

.mobile-menu-container {
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    top: 4rem;
    box-sizing: border-box;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-out;

    &.active {
        visibility: visible;
        opacity: 1;
        transition: all 0.2s ease-in;
    }

    .mobile-menu {
        background: $white;
        position: absolute;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;

        a {
            padding: 1rem;
            display: block;
            min-width: 100vw;
            color: $primaryAction;
            font-size: 1.3rem;
            font-weight: 600;
            padding-right: 2rem;
            text-align: right;
            border-bottom: solid 1px rgba(0, 0, 0, 0.5);
        }
    }
}

.become-partner {
    padding: 0;

    .column {
        padding: 2rem 2rem 2rem 1rem;

        img,
        p {
            max-width: 90%;
            border-radius: 0.2rem;
        }

        &:last-child {
            padding-left: 2rem;
        }
    }

    form {
        label {
            display: block;
            font-weight: 400;
            font-size: 1rem;
            padding-bottom: 0.3rem;
        }

        input[type='file'] {
            padding-left: 0;
            background: transparent;
        }
    }
}

@media (max-width: 600px) {
    .become-partner {
        padding: 0 2rem;

        .columns {
            width: 100%;
            margin-left: 0;
            margin-top: 2rem;
        }

        .column {
            padding: 0;

            img {
                max-width: 98%;
            }

            p {
                max-width: 100%;
            }

            &:last-child {
                padding-left: 0;
                padding-bottom: 2rem;
                margin-top: 2rem;

                input {
                    min-width: 70%;
                }
            }
        }
    }
}

.discover-hero-container {
    .container {
        .discover-container {
            background: $white;
            padding: 2rem 2rem 0.5rem;
            max-width: 35rem;
            border-bottom-right-radius: 0.2rem;
            border-bottom-left-radius: 0.2rem;
            margin-bottom: 8rem;

            p {
                font-weight: 400;
                line-height: 1.5rem;
                color: black;
                margin: 1rem 0 2rem 0;
            }
        }
    }
}

@media (max-width: 800px) {
    .discover-hero-container {
        padding: 0;

        .container {
            .discover-container {
                min-width: 100%;
                margin-bottom: 0;

                .discover-hero-container {
                    background: none !important;
                }
            }
        }
    }
}

.subscribe-for-offers {
    padding: 0 2rem;

    ul > li > p {
        color: #757575;
        font-weight: 500;
    }

    form {
        input[type='text'] {
            width: 100%;
            max-width: 25rem;
        }
    }
}

.footer_container {
    background: $primaryAction;
    padding-top: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;

    @include desktop {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include large-mobile {
        padding-left: 1rem;
        padding-right: 0;
    }

    .footer-logo {
        height: 2.3rem;
        width: auto;
    }

    .left_container {
        display: grid;
        grid-template-rows: 5fr 1fr;
        padding-bottom: 0;

        @include tablet {
            display: block;
        }

        @include large-mobile {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .contact_container {
            display: grid;
            grid-template-rows: 3fr 8fr;

            h2 {
                color: $secondary;
                font-weight: bold;
                font-size: 18px;
            }

            .info_text {
                color: $secondary;

                @include desktop {
                    font-size: 0.9rem;
                }
            }
        }

        .footer_menu_mobile {
            display: none;
            margin-top: 0.2rem;

            @include tablet {
                display: block;
            }

            p {
                color: $secondary;
                font-size: 1.1rem;
                font-weight: 600;
                margin-right: 1rem;
                padding-top: 0.2rem;
                margin-bottom: 1rem;

                @include desktop {
                    font-size: 0.9rem;
                }
            }
        }

        .social_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            @include tablet {
                background: $night-rider;
                padding: 0.5rem;
            }

            @include small-mobile {
                width: 90%;
            }

            .link {
                color: $secondary;
                display: flex;
                align-items: center;
            }

            .button_location {
                color: $secondary;
                background: transparent;
                border: none;
                font-size: 16px;

                span {
                    color: $secondary;
                }

                .icon_location {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .right_container {
        display: grid;
        padding-bottom: 0;

        @include large-mobile {
            display: block;
        }

        .footer_menu_desktop {
            align-content: flex-end;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 0.2rem;

            @include desktop {
                flex-wrap: wrap;
                align-content: flex-start;
            }

            @include tablet {
                display: none;
            }

            .sitemap_container {
                color: $secondary;
                border-right: 1px solid $secondary;
                font-weight: 600;
                font-size: 1.1rem;
                margin-right: 1rem;
                padding-right: 1rem;
                height: 1.5rem;

                @include desktop {
                    font-size: 0.9rem;
                }

                @include tablet {
                    border-right: none;
                }
            }

            p {
                color: $secondary;
                font-size: 1.1rem;
                font-weight: 600;
                margin-right: 1rem;
                padding-top: 0.2rem;
                margin-bottom: 1rem;

                @include desktop {
                    font-size: 0.9rem;
                }
            }
        }

        .partners_product {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            width: auto;

            @include tablet {
                background: $night-rider;
                justify-content: flex-start;
                width: max-content;
                height: max-content;
                border-bottom: 0.5rem solid $cod-gray;
            }

            a {
                margin-left: 1rem;

                img {
                    height: 3rem;
                    object-fit: contain;

                    @include tablet {
                        height: 2.5rem;
                    }

                    @include large-mobile {
                        height: 2rem;
                    }

                    @include mobile {
                        height: 1.8rem;
                    }

                    @include small-mobile {
                        height: 1.4rem;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .trustpilot-widget {

    }
}

.links_mobile {
    display: none;

    @include large-mobile {
        display: block;
        padding: 0 2rem;

        .copyright_text {
            font-size: 12px;
            color: $silver-chalice;
            line-height: 1.2rem;
            margin: 1rem 0;
        }
    }
}

.copyright_container {
    background: $cod-gray;
    border-top: solid 1px darken($silver-chalice, 20%);
    border-bottom: solid 1px darken($silver-chalice, 20%);
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem !important;
    padding-top: 1rem !important;

    @include large-mobile {
        background: $primaryAction;
    }

    .copyright {
        padding-inline: 4rem;
        display: flex;
        justify-content: space-between;

        span {
            color: $silver-chalice !important;
            padding-right: 0.3rem;
        }

        @include large-mobile {
            padding-inline: 2rem;
        }

        .copyright_text {
            font-size: 12px;
            color: $silver-chalice;
            line-height: 1.2rem;

            @include tablet {
                background: $night-rider;
            }
        }

        .links {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;
            row-gap: 2rem;

            @include large-mobile {
                display: none;
            }
        }
    }
}

.info {
    padding: 0 4rem 2rem 4rem;

    p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 1rem;
        line-height: 1.2rem;
        margin-top: 0;
        text-align: center;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

footer {
    background: $cod-gray;

    @include large-mobile {
        background: $primaryAction;
    }
}

@media (max-width: 450px) {
    .info {
        padding: 1rem 0.5rem;
        margin: 0;

        p {
            font-size: 0.85rem;
        }
    }
}

@media (max-width: 800px) {
    .links_mobile {
        position: relative;
        top: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .copyright {
        flex-direction: column;
        padding: 0;
        margin: 0;
    }
}

@media (min-width: 601px) and (max-width: 800px) {
    .partners {
        flex-direction: column;
        justify-content: flex-end;

        .partners_product {
            flex-direction: column !important;
            justify-content: space-around !important;
            min-height: 70%;
            align-self: flex-end;
        }
    }
}

@import '~bulma/css/bulma.min.css';
@import '~antd/dist/antd.css';
@import './mixins';
@import './fonts';
@import 'config/theme';
@import 'config/styleguide';
@import 'components/atoms';
@import 'components/molecules';
@import 'components/organisms';
@import 'components/layout';
@import 'pages/pages';

body {
    font-family: 'Uber Move Text Regular', sans-serif;
    min-height: 100vh;
}
